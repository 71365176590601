import styled from "@emotion/styled"
import SectionWrapper from "../section-wrapper/section-wrapper.component"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"

export const Section = styled.section`
  min-height: 720px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 630px;
    height: calc(100vh - ${({ theme }) => theme.navHeight}px);
  }
`

export const Background = styled(CustomBgImage)`
  width: 90%;
  height: 100%;
  .gatsby-image-wrapper {
    margin-top: 6rem;
  }

  position: absolute;
  left: 0;
  z-index: 0;
  overflow: visible;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`
export const BackgroundMobile = styled(CustomBgImage)`
  width: 100%;
  height: 540px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    margin-top: 3rem;
  }

  ${({ theme }) => theme.breakpoints.up(450)} {
    height: 600px;
  }

  ${({ theme }) => theme.breakpoints.up(500)} {
    height: 720px;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled(Typography)`
  font-weight: 500;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(64)};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(80)};
    line-height: ${({ theme }) => theme.typography.pxToRem(96)};
  }
`

export const Description = styled(Typography)`
  font-weight: 500;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(28)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 3rem;

  max-width: 520px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(22)};
    line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  }
`

export const BackBtn = styled(CustomButton)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(16)};
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: relative;
  z-index: 3;
`
