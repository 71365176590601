import React from "react"

import Layout from "../components/layout"
import Container from "@mui/material/Container"
import { Helmet } from "react-helmet"
import NotFound from "../components/not-found/not-found.component"

// export const Head = () => <title>404: Not Found</title>

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404: Not Found</title>
    </Helmet>
    <NotFound />
  </Layout>
)

export default NotFoundPage
