import React from "react"

import * as S from "./not-found.styles"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "@mui/material"

const NotFound = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      notFound: file(relativePath: { eq: "not-found.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      notFoundMobile: file(relativePath: { eq: "not-found-bg-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Section>
      <S.Background img={staticQuery.notFound} tag="div" />
      <S.BackgroundMobile img={staticQuery.notFoundMobile} tag="div" />
      <Container>
        <S.ContentWrapper>
          <S.Title variant="h1">The page can’t be found.</S.Title>

          <S.Description>
            No need to fret – faster and cheaper closings are right around the
            corner. Let’s get you back on track.
          </S.Description>

          <S.BackBtn href="/">Homepage</S.BackBtn>
        </S.ContentWrapper>
      </Container>
    </S.Section>
  )
}

export default NotFound
